import * as React from 'react';
import { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import './styles/tags.scss';
import PostList from '../components/PostList';

const AllPosts = ({data}) => {
    const metaData = data.site.siteMetadata;
    const mapTitle = data.allMarkdownRemark.edges.map( ({node}, index)=>{
        return(
        <li>
            <a href={node.fields.slug}>
            {index} - Title: {node.frontmatter.title} (tag:{node.frontmatter.tags}, category:{node.frontmatter.category})
            </a>
        </li>
        );
    })
    return(
        <Layout >
            <h1>Title:{data.site.siteMetadata.title}</h1>
            <h2> All Posts </h2>
            <div >
                <p>{mapTitle}</p>
            </div>

        </Layout>
    );
}

export const pageQuery = graphql`
  query {
    site{
        siteMetadata{
            title
            siteUrl
        }
    }
    allMarkdownRemark(filter: {frontmatter: {title: {ne: ""}}})  {
        edges{
            node{
                frontmatter{
                    title
                    tags
                    category
                }
                fields {
                    slug
                }
            }

        }
    }
  }
`;

export default AllPosts;
